import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import partyVerificationDetails from './partyVerificationDetails';
export default {
  name: 'partyVerification',
  watch: {
    currentPage: function() {
      this.getPartyVerification();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyVerification();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  components: {
    BasicSelect,
    ModelSelect,
    appStrings,
    partyVerificationDetails
  },
  props: {},
  data() {
    return {
      vsetCode: null,
      payload: null,
      showChildModal: false,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showValueSetModal: false,
      showAdvSearchModal: false,
      setTimeVsetCode: null,
      parent_value_set_id: null,
      gstnStatus: {
        text: null,
        value: null
      },
      selectAllCheckBox: false,
      gstNumber: null,
      partyVerificationData: [],
      partyVerificationFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'Auto_Schedule',
          stickyColumn: true,
          variant: 'warning'
        },
        {
          key: 'Auto_Schedule_freq',
          label: 'Auto Sch. Freq.'
        },
        {
          key: 'gst_number',
          label: 'GST Number'
        },
        {
          key: 'verification_date'
        },
        {
          key: 'api_status',
          label: 'Status'
        },
        {
          key: 'gstn_status',
          label: 'GSTN Status'
        },
        {
          key: 'gstn_party_name',
          label: 'GSTN Party Name'
        },
        {
          key: 'date_of_cancellation'
        },
        {
          key: 'street_name'
        },
        {
          key: 'door_number'
        },
        {
          key: 'building_name',
          label: 'Building Name'
        },
        {
          key: 'location_name'
        },
        {
          key: 'state_name'
        },
        {
          key: 'error_message'
        },
        {
          key: 'ctb',
          label: 'Constitution of Business'
        },
        {
          key: 'ctj',
          label: 'Centre Jurisdiction'
        },
        {
          key: 'stj',
          label: 'State Jurisdiction'
        },
        {
          key: 'ntr',
          label: 'Nature of Business'
        },
        {
          key: 'stj_cd',
          label: 'State Jurisdiction Code'
        },
        {
          key: 'dty',
          label: 'Taxpayer Type'
        },
        {
          key: 'trade_name'
        },
        {
          key: 'lst_gstn_status',
          label: 'Last GSTN Status'
        },
        {
          key: 'ctj_cd',
          label: 'Centre Jurisdiction Code'
        },
        {
          key: 'rg_dt',
          label: 'Date of Registration'
        },
        {
          key: 'lst_up_dt',
          label: 'Last Update'
        },
        {
          key: 'lst_verification_date',
          label: 'Last Verification Date'
        },
        {
          key: 'lst_cancelation_date',
          label: 'Last Cancelation Date'
        },
        {
          key: 'status',
          label: 'Verification Status'
        },
        {
          key: 'floor_number'
        },
        {
          key: 'pincode'
        }
      ],
      showPartyVerificationModal: false,
      modalTitle: null,
      partyRowDetail: null,
      showExcelUploadModal: false,
    };
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHidePartyVerificationModal(true, 'add');
          this.partyRowDetail = null;
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstrTwoADownload/getPartyVerification ',
            'party-verification',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getPartyVerification() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        gst_number: this.gstNumber,
        gstn_status: this.gstnStatus.value
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/getPartyVerification', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.partyVerificationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    verifyGstNumber() {
      const filterData = this.partyVerificationData.filter(key => key.select);
      let indirectTaxId = '';
      filterData.forEach(elem => {
        // indirectTaxId += elem.party_indirect_tax_id + ',';
        indirectTaxId += elem.verification_id + ',';
      });
      const finalIndirectTaxId = indirectTaxId.replace(/,*$/, '');
      const payload = {
        // selected_indirect_tax_id: finalIndirectTaxId
        selected_verification_id: finalIndirectTaxId
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/verifyGstNumber', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.partyVerificationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateGstnVerification() {
      const filterData = this.partyVerificationData.filter(key => key.select);
      const gstnVerifyDetails = filterData.map(elem => {
        return {
          verification_id: elem.verification_id,
          auto_schedule: elem.Auto_Schedule,
          auto_schedule_freq: elem.Auto_Schedule_freq
        };
      });
      const payload = {
        data: gstnVerifyDetails ? gstnVerifyDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/updateGstnVerification', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.partyVerificationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GSTN_STATUS) {
        this.gstnStatus = {
          value: item.value_meaning,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      (this.gstnStatus = {
        text: null,
        value: null
      }),
        (this.gstNumber = null),
        (this.partyVerificationData = []);
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    checkUncheckAll() {
      this.partyVerificationData = this.partyVerificationData.map((data, index) => {
        data.selectBox = this.selectAllCheckBox;
        if (this.selectAllCheckBox) {
          this.selectBoxChecked(data.selectBox, index);
        }
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      this.partyVerificationData[index].select = flag;
    },

    hideChildModal() {},
    rowSelected(item) {
      this.partyRowDetail = item;
      this.showHidePartyVerificationModal(true);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'GSTN_STATUS') {
        this.gstnStatus = {
          value: null,
          text: null
        };
      }
    },
    showHidePartyVerificationModal(flag, modalTitle) {
      this.showPartyVerificationModal = flag;
      this.modalTitle = modalTitle;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
