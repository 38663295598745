export default {
  components: {},
  props: ['partyRowDetail'],
  data() {
    return {
      loader: false,
      partyVerifyDetailsData: [],
      partyVerifyDetailsFields: [
        {
          key: 'gst_number',
          label: 'GST Number'
        },
        {
          key: 'verification_date'
        },
        {
          key: 'api_status',
          label: 'Status'
        },
        {
          key: 'gstn_status',
          label: 'GSTN Status'
        },
        {
          key: 'gstn_party_name',
          label: 'GSTN Party Name'
        },
        {
          key: 'date_of_cancellation'
        },
        {
          key: 'street_name'
        },
        {
          key: 'door_number'
        },
        {
          key: 'buiding_name',
          label: 'Building Name'
        },
        {
          key: 'location_name'
        },
        {
          key: 'state_name'
        },
        {
          key: 'error_message'
        },
        {
          key: 'ctb',
          label: 'Constitution of Business'
        },
        {
          key: 'ctj',
          label: 'Centre Jurisdiction'
        },
        {
          key: 'stj',
          label: 'State Jurisdiction'
        },
        {
          key: 'ntr',
          label: 'Nature of Business'
        },
        {
          key: 'stj_cd',
          label: 'State Jurisdiction Code'
        },
        {
          key: 'dty',
          label: 'Taxpayer Type'
        },
        {
          key: 'trade_name'
        },
        {
          key: 'lst_gstn_status',
          label: 'Last GSTN Status'
        },
        {
          key: 'ctj_cd',
          label: 'Centre Jurisdiction Code'
        },
        {
          key: 'rg_dt',
          label: 'Date of Registration'
        },
        {
          key: 'lst_up_dt',
          label: 'Last Update'
        },
        {
          key: 'lst_verification_date',
          label: 'Last Verification Date'
        },
        {
          key: 'lst_cancelation_date',
          label: 'Last Cancelation Date'
        },
        {
          key: 'verification_status'
        },
        {
          key: 'gstn_party_name',
          label: 'GSTN Party Name'
        },
        {
          key: 'floor_number'
        },
        {
          key: 'pincode'
        }
      ]
    };
  },
  mounted() {
    if (this.partyRowDetail) {
      this.updateVerificationHistory();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    updateVerificationHistory() {
      const payload = {
        verification_id: this.partyRowDetail.verification_id
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/updateVerificationHistory', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.partyVerifyDetailsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {}
};
